<template>
    <div class="modal-action-sheet">
        <transition name="slide-up">
            <div v-show="show" :class="[isPhoto ? 'photo-sheet' : 'sheet']">
                <div :class="[isPhoto ? 'photo-description' : 'icon-description']">
                    <img
                        v-if="!isPhoto"
                        class="icon-img m-b-20"
                        @click="() => close()"
                        :src="require(`@/assets/images/verification-badge/active/${badge.image_name}.svg`)"
                    />
                    <div
                        v-else-if="isPhoto && options.from === 'myPage'"
                        @click="() => close()"
                        v-lazy:background-image="badge.photos[0]"
                        class="photo-img m-b-28"
                    />
                    <div
                        v-else
                        v-lazy:background-image="badge.photo_url"
                        @click="() => close()"
                        class="photo-img m-b-24"
                    />
                    <div @click="() => close()">
                        <div class="title" v-html="badge.name" />
                        <div class="description" v-html="badge.description" />
                    </div>

                    <DetailDescription :name="badge.name" />
                    <div
                        v-if="options.from === 'myPage' && enableDelete"
                        @click="deleteBadge"
                        class="delete-badge"
                        v-html="'뱃지 삭제'"
                    ></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import DetailDescription from '@/routes/verification-badges/components/DetailDescription.vue'
import verificationBadgeService from '@/services/verification-badge'

export default {
    name: 'ModalBadgeInfo',
    components: { DetailDescription },
    props: ['options'],
    data: () => ({
        show: false,
    }),
    mounted() {
        setTimeout(() => {
            this.show = true
        })
    },
    computed: {
        badge() {
            return this.options.badge
        },
        isPhoto() {
            return this.badge.display_method && this.badge.display_method.includes('photo')
        },
        enableDelete() {
            return ['attraction', 'career', 'financial'].indexOf(this.badge.category) !== -1
        },
    },
    methods: {
        close(handler) {
            this.show = false
            setTimeout(() => {
                this.$emit('close')
                if (handler) handler()
            }, 400)
        },
        async deleteBadge() {
            const idx = await this.$modal.basic({
                body: '획득한 뱃지를 삭제하시겠습니까?',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })

            if (idx === 1) {
                try {
                    const payload = new FormData()

                    payload.append('verification_badge_id', this.badge.verification_badge_id)
                    payload.append('user_id', this.$store.getters.me.id)

                    const { msg } = await verificationBadgeService.updateBadge(payload)
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Complete_DeleteBadge',
                        },
                    })

                    this.$toast.success(msg)
                    this.$store.dispatch('loadMyVerificationBadges')
                    this.$emit('close')
                } catch (e) {
                    this.$toast.error(e.data.msg)
                }
            } else {
                this.$emit('close')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-action-sheet {
    padding: 0 !important;

    .sheet {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        border-radius: 24px;

        @include center;

        .icon-description {
            justify-content: space-between;
            align-items: center;
            padding: 32px 1rem 40px 1rem;
            width: 100%;

            @include flex;

            .delete-badge {
                margin-top: 36px;
                border-radius: 8px;
                border: solid 1px $grey-03;
                width: 100%;
                height: 48px;
                color: $grey-09;
                font-size: 15px;
                text-align: center;
                padding-top: 11px;
                // padding-bottom: 10px;
            }
        }

        .icon-img {
            width: 92px;
            height: 92px;
        }

        .title {
            font-size: 18px;
            margin-bottom: 8px;
            color: black;
            letter-spacing: -0.2px;
            text-align: center;

            @include f-medium;
        }

        .description {
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
        }
    }

    .photo-sheet {
        @extend .sheet;

        .photo-description {
            @extend .icon-description;
            height: 100%;
            padding: 1rem 1rem 3rem 1rem;
        }

        .photo-img {
            width: calc(100vw - 32px);
            height: calc(100vw - 32px);
            padding: 0 16px;
            border-radius: 4px;

            background-size: cover;
        }

        .delete-badge {
            margin-top: 36px;
            border-radius: 8px;
            border: solid 1px $grey-03;
            width: 100%;
            height: 48px;
            color: $grey-09;
            font-size: 15px;
            text-align: center;
            padding-top: 11px;
            // padding-bottom: 10px;
        }
    }

    .detail-description {
        margin-top: 20px;
        max-width: calc(100vw - 48px);
    }
}
</style>
